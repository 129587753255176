import {
  DataTable,
  ColumnDef,
  Icon,
  Button,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'me-component-library';
import { UserData } from '../../types';
import {
  useGetProjectDetail,
  useTranslation,
  useTriggerInviteEmailResend,
} from '../../hooks';
import { useParams } from 'react-router-dom';
import { useGetProjectUsers } from '../../hooks/useGetProjectUsers';
import { DeleteUserModal } from './DeleteUserModal';

export const UserDataTable = () => {
  const params = useParams();
  const { data: projectUserData, isLoading } = useGetProjectUsers(
    params.id as string,
  );
  const { data: projectDetail, isLoading: projectDetailIsLoading } =
    useGetProjectDetail(params.id as string);
  const { mutate, isLoading: triggerIsLoading } = useTriggerInviteEmailResend();
  const t = useTranslation();

  const columns: ColumnDef<UserData>[] = [
    {
      accessorKey: 'email',
      header: t('projectDetail.userList.headers.email'),
    },
    {
      accessorKey: 'roles',
      header: t('projectDetail.userList.headers.roles'),
      cell: ({ getValue }) => {
        const cellValue = getValue();

        if (cellValue === '') {
          return '-';
        }

        return cellValue;
      },
    },
    {
      accessorKey: 'company',
      header: t('projectDetail.userList.headers.company'),
      cell: ({ getValue }) => {
        const cellValue = getValue();

        if (cellValue === '') {
          return '-';
        }

        return cellValue;
      },
    },
    {
      accessorKey: 'name',
      header: t('projectDetail.userList.headers.name'),
      cell: ({ row }) => {
        if (!row.original.firstName && !row.original.lastName) {
          return '-';
        }
        return <>{`${row.original.firstName} ${row.original.lastName}`}</>;
      },
    },
    {
      accessorKey: 'status',
      header: t('projectDetail.userList.headers.status'),
      cell: ({ getValue, row }) => {
        const cellValue = getValue();
        if (cellValue === 'ACTIVE') {
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Icon name="check" className="text-green-500 " />
                </TooltipTrigger>
                <TooltipContent>
                  {t('projectDetail.userList.tooltips.active')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        }

        return (
          <div className="flex justify-around items-center">
            <Icon name="loader" className="text-yellow-500" />
            <Button
              variant="secondary"
              size="sm"
              disabled={triggerIsLoading}
              onClick={() => {
                mutate({
                  projectId: params.id as string,
                  userId: row.original.id,
                  language: projectDetail?.language === 'en' ? 'en' : 'de',
                });
              }}
            >
              {t('projectDetail.userList.actions.resend')}
            </Button>
          </div>
        );
      },
    },
    {
      accessorKey: 'manage',
      header: t('projectDetail.userList.headers.manage'),
      cell: ({ row }) => {
        return (
          <DeleteUserModal
            key={row.original.id}
            email={row.original.email}
            userId={row.original.id}
            projectId={params.id as string}
          />
        );
      },
    },
  ];

  return (
    <div className="w-full mt-6 flex flex-col justify-end">
      {!isLoading && !projectDetailIsLoading && (
        <DataTable columns={columns} data={projectUserData as UserData[]} />
      )}
    </div>
  );
};
