import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Label,
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  cn,
} from 'me-component-library';
import { useCreateUser, useTranslation, useGetProjectEmail } from '../../hooks';
import { useGetProjectUsers } from '../../hooks/useGetProjectUsers';
import { useEffect, useState } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

type Props = {
  closeModal?: () => void;
};
const availableRoles = ['EMAIL_ADMIN', 'EMAIL_USER'];

export const CreateUserForm: React.FC<Props> = ({ closeModal }) => {
  const t = useTranslation();
  const params = useParams();
  const { mutate, isLoading } = useCreateUser();
  const { data: emailData, isLoading: emailsIsLoading } = useGetProjectEmail(
    params.id as string,
  );
  const { data: projectUserData, isLoading: projectDataIsLoading } =
    useGetProjectUsers(params.id as string);
  const [availableEmails, setAvailableEmails] = useState(emailData || []);

  useEffect(() => {
    if (projectUserData && emailData) {
      setAvailableEmails(
        emailData.filter(
          email =>
            !projectUserData.some(
              projectUser => projectUser.email === email.email,
            ),
        ),
      );
    }
  }, [projectUserData, emailData]);

  const formSchema = z.object({
    email: z.string().email().nonempty(),
    firstName: z.string(),
    lastName: z.string(),
    company: z.string(),
    roles: z.enum(['EMAIL_ADMIN', 'EMAIL_USER']),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      roles: 'EMAIL_USER',
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutate(
      {
        ...values,
        roles: [values.roles],
        emailId: emailData?.filter(email => email.email === values.email)[0]
          .id as string,
        projectId: params.id as string,
      },
      {
        onSuccess: closeModal,
      },
    );
  }

  if (emailsIsLoading === true || projectDataIsLoading === true) {
    return '...';
  }

  if (
    availableEmails.length === 0 &&
    emailsIsLoading === false &&
    projectDataIsLoading === false
  ) {
    return (
      <Label>{t('projectDetail.addUserForm.fields.noNewEmailAvailable')}</Label>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          key="email"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t('projectDetail.addUserForm.fields.email.label')}
              </FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        'w-full justify-between',
                        !field.value && 'text-muted-foreground',
                      )}
                    >
                      {field.value
                        ? availableEmails.find(
                            email => email.email === field.value,
                          )?.email
                        : t('projectDetail.addUserForm.fields.email.select')}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput
                      placeholder={t(
                        'projectDetail.addUserForm.fields.email.search',
                      )}
                    />
                    <CommandList>
                      <CommandEmpty>
                        {t(
                          'projectDetail.addUserForm.fields.email.searchNotFound',
                        )}
                        .
                      </CommandEmpty>
                      <CommandGroup>
                        {availableEmails.map(({ email, id }) => (
                          <CommandItem
                            value={email}
                            key={id}
                            onSelect={() => {
                              form.setValue('email', email);
                            }}
                          >
                            {email}
                            <Check
                              className={cn(
                                'ml-auto',
                                email === field.value
                                  ? 'opacity-100'
                                  : 'opacity-0',
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="firstName"
          key="firstName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t('projectDetail.addUserForm.fields.firstName.label')}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t(
                    'projectDetail.addUserForm.fields.firstName.placeholder',
                  )}
                  data-testid="firstName-input"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          key="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('projectDetail.addUserForm.fields.lastName.label')}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t(
                    'projectDetail.addUserForm.fields.lastName.placeholder',
                  )}
                  data-testid="lastName-input"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="company"
          key="comp"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('projectDetail.addUserForm.fields.company.label')}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t(
                    'projectDetail.addUserForm.fields.company.placeholder',
                  )}
                  data-testid="company-input"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="roles"
          key="roles"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t('projectDetail.addUserForm.fields.role.label')}
              </FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t(
                          'projectDetail.addUserForm.fields.role.placeholder',
                        )}
                      />
                    </SelectTrigger>
                  </FormControl>
                  {!emailsIsLoading && !projectDataIsLoading && (
                    <SelectContent>
                      {availableRoles.map(value => (
                        <SelectItem key={value} value={value}>
                          {t(
                            value === 'EMAIL_ADMIN'
                              ? 'projectDetail.addUserForm.fields.role.options.admin'
                              : 'projectDetail.addUserForm.fields.role.options.user',
                          )}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  )}
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full flex justify-end">
          <Button
            type="submit"
            isLoading={isLoading}
            data-testid="addUserSubmit-button"
          >
            {t('projectDetail.addUserForm.buttonLabels.addUser')}
          </Button>
        </div>
      </form>
    </Form>
  );
};
